export default {
  firebase: {
    apiKey: 'AIzaSyALTtmwVLTVAIsIBydBDUi-zzPWT9HQgGA',
    authDomain: 'desjardins-poc.firebaseapp.com',
    projectId: 'desjardins-poc',
    storageBucket: 'desjardins-poc.appspot.com',
    messagingSenderId: '622574900143',
    appId: '1:622574900143:web:a72cb0c193cea572f9955a'
  }
}

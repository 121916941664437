<template>
  <div class="radios-selector">
    <div v-for="{ id, label } in items" :key="id" class="radios-selector__item">
      <input :id="id" v-model="picked" type="radio" :value="id" />
      <label :for="id">{{ label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        { id: 'one', label: 'One' },
        { id: 'two', label: 'Two' }
      ]
    },
    selected: {
      type: String,
      default: 'one'
    }
  },
  emits: ['update:selected'],
  data() {
    return {
      picked: this.selected || 'one'
    }
  },
  watch: {
    picked(val) {
      this.$emit('update:selected', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.radios-selector {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: left;
  justify-content: left;
  white-space: nowrap;
  &__item {
    position: relative;
    padding-left: 0.8rem;
    margin-top: 8px;

    input {
      display: none;

      & + label {
        cursor: pointer;
      }
    }

    & input:checked {
      & + label::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        border-left: 2px solid green;
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
        background-color: rgba(156, 156, 156, 0.2);
        animation: backgroundAnimation 0.4s ease-in-out forwards;
      }
    }
  }
}

@keyframes backgroundAnimation {
  from {
    width: 0;
  }
  to {
    width: 12rem;
  }
}
</style>

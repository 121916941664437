<template>
  <div>
    <gl-map ref="mapRef" :init-map-options="options" />
  </div>
</template>

<script>
import GlMap from '@anagraph/gl-map'
import { useToggleLayers } from '@anagraph/composable-map'
import { shallowRef, onMounted, toRef, watch } from 'vue'

export default {
  components: {
    GlMap
  },
  props: {
    token: {
      type: String,
      default: null
    },
    layersConfig: {
      type: Array,
      default: () => []
    },
    visibleLayers: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Array,
      default: () => []
    }
  },
  emits: ['loaded', 'features-updated'],
  setup(props, { emit }) {
    const filter = toRef(props, 'filter')
    const mapRef = shallowRef(null)
    const visibleLayersRef = toRef(props, 'visibleLayers')
    useToggleLayers(mapRef, props.layersConfig, visibleLayersRef)
    const defaultZoom = 7
    const minZoom = 1.1
    onMounted(() => {
      const map = mapRef.value.map
      map.on('load', () => {
        watch(filter, newVal => {
          if (!newVal) return
          newVal.map(({ layerId, filter: mapboxFilter }) => {
            mapRef.value.map.setFilter(layerId, mapboxFilter)
          })
        })
        emit('loaded', true)
      })
      map.on('idle', () => {
        const map = mapRef.value.map
        const features = map.queryRenderedFeatures({
          layers: visibleLayersRef.value
        })
        emit('features-updated', features)
        emit('loaded', true)
      })
    })
    const transformRequest = (url, resourceType) => {
      if (!process.env.VUE_APP_PROTECTED_TILES_URL) return
      if (
        resourceType === 'Tile' &&
        url.startsWith(process.env.VUE_APP_PROTECTED_TILES_URL)
      ) {
        return {
          url: url,
          headers: { Authorization: `Bearer ${props.token}` }
        }
      }
    }
    return {
      mapRef,
      options: {
        minZoom: minZoom,
        zoom: defaultZoom,
        transformRequest
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <div class="home">
    <left-panel-layout :is-left-panel-open="mapLoaded" class="app-layout">
      <template #left-panel>
        <div v-if="mapLoaded">
          <div class="left-panel-content-container">
            <h2 class="title">Title</h2>
            <radio-selector
              v-model:selected="selected"
              :items="layersConfig.map(({ id }) => ({ id, label: id }))"
            />
            <category-widget
              ref="widgetRef"
              :items="categoryWidgetItems"
              filter-type="simple-filter"
              @filter="applyFilter"
            />
          </div>
        </div>
        <div v-else>loading ...</div>
      </template>
      <template #map>
        <Map
          :token="token"
          :layers-config="layersConfig"
          :visible-layers="[selected]"
          :filter="mapFilter"
          @loaded="mapLoaded = true"
          @features-updated="featuresUpdated"
        />
      </template>
    </left-panel-layout>
    <!-- <panel class="home__right-panel" /> -->
  </div>
</template>

<script>
import LeftPanelLayout from '@anagraph/left-panel-layout'
import CategoryWidget from '@anagraph/category-widget'
import Map from '@/components/map'
import layersConfigurations from '@/layer-configurations'
import layerSymbologies from '@/layer-symbologies'
import RadioSelector from '@/components/radio-selector'
import {
  addLayersSymbologies,
  getCategoryWidgetItems,
  createLayerFilter
} from '@/symbologiesHelper.js'
// import Panel from '@anagraph/panel'
import { getToken } from '@/commons/auth/firebase-auth'
import { computed, onMounted, ref, watch } from 'vue'

export default {
  name: 'Home',
  components: {
    LeftPanelLayout,
    Map,
    RadioSelector,
    CategoryWidget
    // Panel
  },
  setup() {
    const layersConfig = addLayersSymbologies(
      layersConfigurations,
      layerSymbologies
    )
    const mapLoaded = ref(false)
    const token = ref(null)
    const widgetRef = ref(null)
    const mapFilter = ref([])
    const selected = ref(layersConfigurations[0].id) // change me !
    const categoryWidgetItems = ref([])
    const visibleLayers = computed(() => [selected.value])
    watch(visibleLayers, () => widgetRef.value.$refs.filters.reset())
    onMounted(async () => {
      token.value = await getToken()
    })
    const featuresUpdated = features => {
      categoryWidgetItems.value = getCategoryWidgetItems(
        visibleLayers.value[0],
        layerSymbologies,
        features
      )
    }
    const applyFilter = filter => {
      mapFilter.value = createLayerFilter(
        filter,
        visibleLayers.value[0],
        layerSymbologies
      )
    }
    return {
      mapLoaded,
      token,
      layersConfig,
      selected,
      widgetRef,
      categoryWidgetItems,
      visibleLayers,
      featuresUpdated,
      applyFilter,
      mapFilter
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  &__right-panel {
    position: absolute;
    width: 300px;
    background-color: rgb(241, 237, 237);
    z-index: 500;
    top: 50px;
    right: 50px;
  }
}

.left-panel-content-container {
  margin: 2rem;

  & .title {
    text-align: left;
  }
}
</style>

import randomPointsDesjardins from './random-points-desjardins'
export default [
  {
    id: 'Fake VPN Connections',
    property: 'colored',
    geometryType: 'circle',
    symbologyType: 'categories',
    symbologyItems: randomPointsDesjardins,
    defaultColor: 'black'
  }
]

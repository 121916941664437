import randompointsdesjardins from './randompointsdesjardins.json'
// [ 'case', ['boolean', ['==', ['get', 'colored'], 'red'], true], 3, 1 ]
export default [
  {
    id: 'Fake VPN Connections',
    source: {
      type: 'geojson',
      data: randompointsdesjardins
    },
    style: {
      type: 'circle',
      layout: {
        'circle-sort-key': [
          'case',
          ['boolean', ['==', ['get', 'colored'], 'red'], true],
          2,
          1
        ]
      },
      paint: {
        'circle-color': ['get', 'colored'],
        'circle-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          5,
          [
            'case',
            ['boolean', ['==', ['get', 'colored'], 'red'], true],
            1,
            0.3
          ],
          12,
          1
        ],
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          ['case', ['boolean', ['==', ['get', 'colored'], 'red'], true], 2, 1],
          5,
          ['case', ['boolean', ['==', ['get', 'colored'], 'red'], true], 2, 2],
          10,
          ['case', ['boolean', ['==', ['get', 'colored'], 'red'], true], 6, 3],
          14,
          ['case', ['boolean', ['==', ['get', 'colored'], 'red'], true], 12, 4]
        ]
      }
    }
  }
]
